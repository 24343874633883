<template>
  <CButton  @click="login" >
    <span class="mr-2 mb-2">Login With</span>
    <font-awesome-icon :icon="['fab', 'windows']" :style="{ color: '#aa945b'}" size="2x" class="mt-2"/>
  </CButton>
</template>

<script>
export default {
  name: "LoginWithMicrosoft",

  computed: {
    url: () => `/api/oauth/graph`
  },
  mounted() {
    window.addEventListener("message", this.onMessage, false);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },

  methods: {
    async login() {
      const newWindow = openWindow("", "Login");

      const url = await this.$store.dispatch("auth/fetchOauthUrl", {
        provider: "graph"
      });

      newWindow.location.href = url;
    },

    /**
     * @param {MessageEvent} e
     */
    onMessage(e) {
//      if (e.origin !== window.origin || !e.data.token) {
      if (!e.data.token) {
        return;
      }

      if (this.$session.exists()) {
        //TODO fix
        // this.$session.destory();
      }

      this.$store.dispatch("auth/saveToken", {
        token: e.data.token
      });
		
      // Fetch the user.
      this.$store.dispatch("auth/fetchUser");
      this.$router.push('/dashboard');

//      this.$router.push({ name: "home" });
    }
  }
};

/**
 * @param  {Object} options
 * @return {Window}
 */
function openWindow(url, title, options = {}) {
  if (typeof url === "object") {
    options = url;
    url = "";
  }

  options = { url, title, width: 600, height: 720, ...options };

  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;

  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;

  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");

  const newWindow = window.open(url, title, optionsStr);

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
}
</script>

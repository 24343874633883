<template>
  <div class="c-app flex-row align-items-center login-background">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4" style="border-color: #A69164;">
              <CCardBody>
                <CForm>
                  <CRow >
                      <CCol sm="5">
                        <h1  class="card-title mb-0">Login</h1>
                      </CCol>
                      <CCol sm="7">
                        <img src="./logo-header.png" class="float-right" size="custom-size" height="20" />
                      </CCol>

                  </CRow>
                  <p class="text-muted">Sign In to your account</p> 

                  <CInput placeholder="Username"
                    autocomplete="username email" v-model="email" tooltipFeedback>
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password" type="password" v-model="password"
                    autocomplete="curent-password">
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton class="px-4" style="background-color:#A69164;color:white" @click="logMeIn">Login</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                    </CCol>
                    <CCol col="12" align="center">
                        <login-with-microsoft/>
                    </CCol>
                  </CRow>
                  <CRow v-if="showToast" class="mt-2">
                   <CCol col="12" >
                      <span style="color:red">{{error_message}}</span>
                   </CCol>
                 
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard> -->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import {login} from '../../api.js';
import LoginWithMicrosoft from "./LoginWithMicrosoft.vue";

export default {
  name: 'Login',
  components:{
    LoginWithMicrosoft
  },
  data(){
    return{
//      email:'caroline.nyein@thelearninglab.com.sg',
//      password:'123456',
      showToast:false,
      error_message:'',
      remember:false
    }
  },
  methods:{
    async logMeIn()
    {
      if(this.email && this.password)
      {
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
         await axios.post(login, { username: this.email, password:this.password }).then(res => {
            if(res.status == 200)
            {

                  if (this.$sesion && this.$session.exists()) {
                      this.$session.destroy();
                  }

                    // Save the token.
                    this.$store.dispatch("auth/saveToken", {
                      token: res.data.access_token,
                      remember: this.remember
                    });

                  // Fetch the user.
                  this.$store.dispatch("auth/fetchUser");
                  this.$router.push('/dashboard');
            }
            else
            {
              this.error_message = res
              this.showToast = true
            }
        }).catch((error) => {
            this.error_message = error
            this.showToast = true
        });
      }
      else
      {
          this.error_message = "Please provide username and password"
          this.showToast = true
      }
    },
    checkLogin() {
      if (Cookies.get('token')) {
        this.$router.push('/dashboard');
      }
    }
  },
  created() {
    this.checkLogin();
  }
}

</script>
<style>
.login-background{
  background-image: url("./background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>